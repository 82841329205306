import { Card, List, ListItem, ListItemIcon, ListItemText } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

import { Button } from "../../components/Button";
import { SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceNoteIdentifier } from "../constants";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";

export interface WorkplaceCheckInInstructionsCardProps {
  workplaceId: string;
}

export function WorkplaceCheckInInstructionsCard(props: WorkplaceCheckInInstructionsCardProps) {
  const { workplaceId } = props;

  const { data: notes = {} } = useWorkplaceNotesParsed(workplaceId);
  const { generateModalPath } = useWorkplaceModalsDataContext();

  const parkingNote = notes[WorkplaceNoteIdentifier.PARKING_INSTRUCTIONS]?.note;
  const entranceNote = notes[WorkplaceNoteIdentifier.ENTRANCE_INSTRUCTIONS]?.note;

  if (!isDefined(parkingNote) && !isDefined(entranceNote)) {
    return null;
  }

  return (
    <Card>
      <CardContent sx={{ padding: 7 }}>
        <Text semibold variant="body1" sx={{ marginBottom: 7 }}>
          Check-in Instructions
        </Text>

        <List dense>
          {isDefined(parkingNote) && (
            <ListItem>
              <ListItemIcon iconType="car" />
              <ListItemText primary="Parking" secondary={parkingNote} />
            </ListItem>
          )}

          {isDefined(entranceNote) && (
            <ListItem>
              <ListItemIcon iconType="exit" />
              <ListItemText primary="Entrance" secondary={entranceNote} />
            </ListItem>
          )}
        </List>

        <Button
          fullWidth
          variant="outlined"
          size="medium"
          sx={{ marginTop: 8 }}
          href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH, {
            workplaceId,
          })}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_CHECK_IN_INSTRUCTIONS_OPENED, {
              workplaceId,
            });
          }}
        >
          View check-in instructions
        </Button>
      </CardContent>
    </Card>
  );
}
