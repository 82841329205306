import { DotSeparatedList } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";

interface WorkplaceProfileHeadingProps {
  type: string;
  distanceText?: string;
}
export function WorkplaceProfileHeading(props: WorkplaceProfileHeadingProps) {
  const { type, distanceText } = props;

  return (
    <Box>
      <DotSeparatedList>
        <Text semibold variant="caption" color={(theme) => theme.palette.text.tertiary}>
          {type}
        </Text>

        {distanceText && (
          <Text semibold variant="caption" color={(theme) => theme.palette.text.tertiary}>
            {distanceText}
          </Text>
        )}
      </DotSeparatedList>
    </Box>
  );
}
