import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import { LoadingButton } from "../../components/LoadingButton";
import { SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { WorkplaceModalContent } from "../Modal/Content";
import { WorkplaceModalFloatingFooter } from "../Modal/FloatingFooter";
import { useWorkplaceOpenShiftsData } from "../OpenShifts/useWorkplaceOpenShiftsData";
import type { WorkplaceLocationState, WorkplaceProfile } from "../types";
import { WorkplaceProfileModalContent } from "./ModalContent";
import { WorkplaceProfileModalContentSkeleton } from "./ModalContentSkeleton";

interface WorkplaceProfileModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading?: boolean;
}

export function WorkplaceProfileModal(props: WorkplaceProfileModalProps) {
  const { modalState, workplaceId, workplace, isLoading } = props;

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const {
    data: { unfilteredWorkplaceShifts },
    isLoading: openShiftsIsLoading,
  } = useWorkplaceOpenShiftsData(workplace);

  const hasOpenShifts = unfilteredWorkplaceShifts.length > 0;

  const scrollRef = useRef<HTMLDivElement>(null);

  useScrollToTopOnStatusTap(scrollRef);

  const location = useLocation<WorkplaceLocationState>();

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <StickyScrollPageHeader
        title={workplace?.name ?? ""}
        isLoading={isLoading}
        scrollContainerRef={scrollRef}
      />

      <WorkplaceModalContent ref={scrollRef}>
        {isDefined(workplace) && <WorkplaceProfileModalContent workplaceProfile={workplace} />}

        {isLoading && !isDefined(workplace) && <WorkplaceProfileModalContentSkeleton />}
      </WorkplaceModalContent>

      <WorkplaceModalFloatingFooter>
        {location.state?.origin !== "WORKPLACE_OPEN_SHIFTS_MODAL" && (
          <LoadingButton
            fullWidth
            isLoading={openShiftsIsLoading}
            variant="contained"
            invert={!hasOpenShifts}
            disabled={!hasOpenShifts}
            sx={(theme) => ({
              // !important is needed to override the default `box-shadow: none` on the button
              boxShadow: `${theme.shadows[3]} !important`,
            })}
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
              workplaceId,
            })}
            locationState={{
              origin: "WORKPLACE_PROFILE_MODAL",
            }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_OPEN_SHIFTS_OPENED, {
                workplaceId,
                trigger: "Workplace Profile Modal",
              });
            }}
          >
            {hasOpenShifts
              ? `View ${unfilteredWorkplaceShifts.length} open shifts`
              : "No open shifts right now"}
          </LoadingButton>
        )}
      </WorkplaceModalFloatingFooter>
    </FullScreenDialog>
  );
}
