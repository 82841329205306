import { AsOfDateText, Card, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent } from "@mui/material";
import { parseISO } from "date-fns";

import { WorkplaceNoteIdentifier } from "../constants";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";

interface WorkplaceImportantInformationCardProps {
  workplaceId: string;
}

export function WorkplaceImportantInformationCard(props: WorkplaceImportantInformationCardProps) {
  const { workplaceId } = props;

  const { data: notes = {} } = useWorkplaceNotesParsed(workplaceId);

  const importantInfo = notes[WorkplaceNoteIdentifier.PROFILE_DESCRIPTION];

  if (!isDefined(importantInfo) || !isDefined(importantInfo.note)) {
    return null;
  }

  return (
    <Card>
      <CardContent sx={{ padding: 7, pb: 7 }}>
        <Pill
          size="medium"
          backgroundColor={(theme) => theme.palette.shift?.urgent.background}
          color={(theme) => theme.palette.shift?.urgent.text}
          label="Important Info"
          sx={{ marginBottom: 7 }}
        />

        <AsOfDateText
          date={importantInfo.updatedAt ? parseISO(importantInfo.updatedAt) : new Date()}
          sx={{ paddingBottom: 4 }}
        />

        <Text semibold variant="body1" color={(theme) => theme.palette.text.secondary}>
          {importantInfo.note}
        </Text>
      </CardContent>
    </Card>
  );
}
