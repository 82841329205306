import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useCreateChatChannel } from "@src/appV2/Facilities/Chat/api/useCreateChatChannel";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { generatePath, useHistory } from "react-router-dom";

import { Button } from "../../components/Button";

interface WorkplaceProfileActionsProps {
  workplaceId: string;
  phoneNumber?: string;
  displayChatButton?: boolean;
}

export function WorkplaceProfileActions(props: WorkplaceProfileActionsProps) {
  const { workplaceId, phoneNumber, displayChatButton = true } = props;
  const worker = useDefinedWorker();
  const history = useHistory();

  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel();

  return (
    <Stack direction="row" spacing={3}>
      {displayChatButton && (
        <Button
          invert
          startIconType="text-bubble"
          size="small"
          variant="contained"
          disabled={isCreatingChatChannel}
          sx={(theme) => ({ boxShadow: theme.shadows[2] })}
          onClick={async () => {
            await createChatChannel({
              facilityId: workplaceId,
              agentId: worker.userId,
            });

            history.push(
              generatePath(DeprecatedGlobalAppV1Paths.CHAT, {
                facilityId: workplaceId,
              })
            );
          }}
        >
          Chat with workplace
        </Button>
      )}
      {isDefined(phoneNumber) && (
        <Button
          invert
          startIconType="phone"
          size="small"
          variant="contained"
          href={`tel:+1${phoneNumber}`}
          sx={(theme) => ({ boxShadow: theme.shadows[2] })}
        >
          Call
        </Button>
      )}
    </Stack>
  );
}
