import { HorizontalScrollView } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { type SxProps, type Theme } from "@mui/material";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { MapWithAddress } from "../../GeoLocation/MapWithAddress";

interface WorkplaceProfileLocationAndGalleryProps {
  workplaceId: string;
  workplaceName?: string;
  location: Location;
  scrollViewOuterPaddingCompensation?: number;
  scrollViewSx?: SxProps<Theme>;
}

export function WorkplaceProfileLocationAndGallery(props: WorkplaceProfileLocationAndGalleryProps) {
  const { workplaceId, workplaceName, location, scrollViewSx, scrollViewOuterPaddingCompensation } =
    props;

  const { data: photos, isSuccess: photosIsSuccess } = useFacilityPhotos(
    {
      facilityId: workplaceId,
    },
    {
      enabled: isDefined(workplaceId) && workplaceId !== "",
    }
  );

  return (
    <HorizontalScrollView
      stretch
      outerPaddingCompensation={scrollViewOuterPaddingCompensation}
      sx={scrollViewSx}
    >
      <MapWithAddress workplaceId={workplaceId} workplaceName={workplaceName} location={location} />

      {photosIsSuccess &&
        photos.photosList.map((photo) => (
          <img key={photo._id} src={photo.url} alt={photo.name} loading="lazy" />
        ))}
    </HorizontalScrollView>
  );
}
